@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  opacity: 0;
  z-index: 0;
  margin: 0 !important;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  height: 100%;
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  pointer-events: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.splide__pagination li {
  pointer-events: auto;
  margin: 0;
  line-height: 1;
  list-style-type: none;
  display: inline-block;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  visibility: hidden;
  position: relative;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  list-style-type: none !important;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  contain: strict;
  border: 2px solid #999;
  border-left-color: #0000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: auto;
  animation: 1s linear infinite splide-loading;
  display: inline-block;
  position: absolute;
  inset: 0;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  z-index: 0;
  position: relative;
  overflow: hidden;
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

:root {
  --base-rem: 16px;
  --font-family: Inter, sans-serif;
  --base-color: #999;
  --dark-color: #0a0a0f;
  --btn-grad: linear-gradient(50deg, #0537a8 4.69%, #013bff 88.09%);
  --primary-black: #0a0a0f;
  --primary-blue: #05f;
  --secondary-gray-light: #e8e8ed;
}

html {
  font-size: var(--base-rem);
}

body {
  color: var(--base-color);
  background-color: var(--dark-color);
  font-size: 1rem;
  font-family: var(--font-family);
}

.container {
  position: relative;
}

.btn {
  white-space: normal;
  cursor: pointer;
  background: none;
  border: none;
  padding: 15px 2rem 15px 2.5rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

.btn__arrow {
  vertical-align: top;
  width: 20px;
  margin-top: 2px;
  margin-bottom: -2px;
  display: inline-block;
}

.btn__arrow svg {
  vertical-align: top;
}

.btn-simple {
  color: currentColor;
  padding: .44rem 0;
}

.btn-simple:hover .btn__content {
  text-decoration: underline;
}

.btn-light {
  color: currentColor;
  border-radius: .5rem;
}

.btn-simple .btn__arrow {
  opacity: .5;
}

.btn-main {
  color: #fff;
  background: var(--btn-grad);
  border: 1px solid #ffffff26;
  border-radius: .5rem;
}

.btn-main:hover {
  background: linear-gradient(0deg, #0003 0% 100%), linear-gradient(50deg, #0537a8 4.69%, #013bff 88.09%);
  border-color: #ffffff1a;
}

.btn-main:active {
  background: linear-gradient(0deg, #0000004d 0% 100%), linear-gradient(50deg, #0537a8 4.69%, #013bff 88.09%);
  border-color: #ffffff1a;
  border-radius: 8px;
}

.container-inner {
  width: 100%;
  max-width: 1210px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo svg {
  vertical-align: top;
  width: 152px;
  height: 30px;
}

.top {
  padding: 1rem 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.top__action-button .btn {
  color: #fff;
  background: #ffffff1a;
  border-radius: .375rem;
  padding: .625rem .75rem .625rem 1rem;
  font-size: .875rem;
  font-weight: 500;
}

.top__action-button .btn:hover {
  box-shadow: inset 0 0 0 1px #fff3;
}

.top__action-button .btn:active {
  background: #fff3;
  box-shadow: inset 0 0 0 1px #fff3;
}

.top__action-button .btn__arrow {
  opacity: .5;
  margin-top: 0;
}

.top .container-inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.hero {
  color: #fff;
  background: url("hero-block-md.cadcd60c.jpg") 100% 100% no-repeat;
  align-items: center;
  min-height: 39.375rem;
  display: flex;
}

.hero__inner {
  max-width: 50rem;
}

.hero__content {
  letter-spacing: .0125rem;
  max-width: 35.5625rem;
  margin-bottom: 2.5rem;
}

.hero__content p {
  margin-bottom: 1rem;
}

.hero__subtitle {
  letter-spacing: .025rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 1.875rem;
}

.hero-mainheader {
  color: var(--primary-blue);
  letter-spacing: .0375rem;
  height: 4.375rem;
  margin-bottom: 1.5rem;
  font-size: 3.75rem;
  font-weight: normal;
  line-height: 4.375rem;
  position: relative;
  overflow: hidden;
}

.hero-mainheader__word {
  white-space: nowrap;
  opacity: 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-mainheader__word:first-child {
  opacity: 1;
}

.main-services {
  background-color: #fff;
  padding: 5rem 0;
}

.main-services__title {
  letter-spacing: normal;
  margin-bottom: .5rem;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
}

.main-services__list {
  margin: 0 0 0 -1.88rem;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.main-services__soon {
  background: var(--primary-blue);
  border: 1px solid #00a5ff;
  border-radius: 1.5rem;
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.2;
  position: absolute;
  bottom: .75rem;
  right: .62rem;
}

.main-services__list-item {
  flex: 1;
  margin-left: 1.88rem;
  display: flex;
}

.main-services__item {
  backdrop-filter: blur(2px);
  letter-spacing: .0125rem;
  border-radius: 1.5rem;
  padding: 2.5rem 2.5rem 10.25rem;
  position: relative;
}

.main-services__item--payment {
  color: var(--primary-black);
  background: #e8e8ed url("main-services-payments.283f32ee.jpg") 50% 100% / 370px no-repeat;
}

.main-services__item--otc {
  color: #fff;
  background: var(--primary-blue) url("main-services-otc.2f7a8703.jpg") no-repeat 50% 100% / 370px auto;
}

.main-services__item--custodyservices {
  color: #fff;
  background: var(--primary-black) url("main-services-custodyservices.072851d5.jpg") no-repeat 50% 100% / 100% auto;
}

.main-hero-payments {
  padding: 7.5rem 0;
  position: relative;
}

.main-hero-payments__content {
  max-width: 50rem;
  margin-bottom: 7.5rem;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
}

.main-hero-payments__content p {
  margin-bottom: 2.5rem;
}

.main-hero-payments__title {
  color: #fff;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
}

.main-hero-payments__visual {
  min-height: 35rem;
}

.main-hero-payments__visual:before {
  content: "";
  pointer-events: none;
  z-index: -1;
  background: url("hero-payments.e3dc442d.jpg") 50% 0 / auto 100% no-repeat;
  height: 1180px;
  margin-top: -220px;
  position: absolute;
  left: 0;
  right: 0;
}

@media (-webkit-device-pixel-ratio >= 2), (resolution >= 192dpi) {
  .main-hero-payments__visual:before {
    background-image: url("hero-payments@2x.4d3d31b1.jpg");
  }
}

.main-hero-payments .main-list-benefits {
  margin-bottom: 12.5rem;
}

.main-hero-payments__glow {
  z-index: -1;
  background: url("main-hero-payments-benefits-bg.56c17780.jpg") 50% / auto 100% no-repeat;
  height: 700px;
  position: absolute;
  left: 0;
  right: 0;
}

.main-list-benefits__list {
  grid-template-columns: repeat(3, 1fr);
  margin-left: -1.88rem;
  padding: 0;
  list-style-type: none;
  display: grid;
}

.main-list-benefits__content {
  max-width: 16.875rem;
}

.main-list-benefits__list-item {
  backdrop-filter: blur(8px);
  text-align: center;
  color: #fff;
  background: #1f1f1fd9 url("main-list-benefits-bd.320baa82.png") 50% 0 / auto 1px no-repeat;
  border-radius: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 11.25rem;
  margin-bottom: 1.88rem;
  margin-left: 1.88rem;
  padding: 1.5rem;
  font-size: 1.25rem;
  line-height: 1.625rem;
  display: flex;
}

.main-list-benefits__ico {
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-bottom: 1rem;
  display: flex;
}

.main-list-benefits__ico svg, .main-list-benefits__ico img {
  vertical-align: top;
  width: 48px;
  height: auto;
}

.main-hero-payments-coverage {
  display: flex;
}

.main-hero-payments-coverage__content {
  flex: auto;
}

.main-hero-payments-coverage__globe {
  flex: none;
  width: 37.5rem;
  height: 31.25rem;
  margin-left: 2.94rem;
}

.main-hero-payments-coverage__title {
  color: #fff;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
}

.main-hero-payments-coverage__flags {
  margin-bottom: 3.5rem;
  display: flex;
}

.main-hero-payments-coverage-list {
  flex: 1;
  margin-left: -1.88rem;
  padding: 0;
  list-style-type: none;
}

.main-hero-payments-coverage-list__list-item {
  padding: 0 0 1rem 1.88rem;
  display: flex;
}

.main-hero-payments-coverage-list__content {
  letter-spacing: .025rem;
  margin-left: .69rem;
  padding-top: 1px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.875rem;
}

.main-hero-payments-coverage-list__more {
  border: 1px solid var(--primary-blue);
  box-sizing: border-box;
  color: var(--primary-blue);
  text-align: center;
  border-radius: .5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
}

.main-hero-payments-coverage-list__more svg {
  vertical-align: top;
  margin: auto;
}

.main-hero-payments-coverage-list__flag {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  flex: none;
  width: 2rem;
  height: 2rem;
}

.main-revolution-payments {
  background-color: #fff;
  padding: 6rem 0 5rem;
}

.main-revolution-payments__title {
  text-align: center;
  color: var(--primary-black);
  margin-bottom: 3.5rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
}

.main-revolution-payments__list {
  flex-direction: column;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.main-revolution-payments__item {
  border-radius: 1.5rem;
  align-items: center;
  min-height: 28.8rem;
  margin-bottom: 2.5rem;
  display: flex;
  position: relative;
}

.main-revolution-payments__figure {
  text-align: center;
  flex: none;
  width: 35.625rem;
}

.main-revolution-payments__figure img, .main-revolution-payments__figure object {
  vertical-align: top;
}

.main-revolution-payments__content {
  letter-spacing: .0125rem;
  padding: 1.88rem 4.38rem 1.88rem 1.88rem;
}

.main-revolution-payments__content h3 {
  letter-spacing: normal;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
}

.main-revolution-payments__content p {
  margin-bottom: 2rem;
}

.main-revolution-payments--optimize {
  background: var(--secondary-gray-light) url("revolution-bg-1.5a903fdc.svg") no-repeat 0 0 / auto 100%;
  color: var(--primary-black);
}

.main-revolution-payments--optimize .main-revolution-payments__figure object {
  width: 490px;
}

.main-revolution-payments--simplify {
  background: var(--primary-black) url("revolution-bg-1.5a903fdc.svg") no-repeat 0 0 / auto 100%;
  color: #fff;
}

.main-revolution-payments--simplify .main-revolution-payments__figure {
  transform: translate(-15px);
}

.main-revolution-payments--growth {
  background: var(--primary-blue) url("revolution-bg-2.1d28bdec.svg") no-repeat 0 0 / auto 100%;
  color: #fff;
}

.main-revolution-payments--growth .main-revolution-payments__figure object {
  width: 398px;
}

.main-sections-wrapper {
  background: url("main-section-bg.420e9926.jpg") 0 0 / 968px no-repeat;
}

.main-opportunities {
  padding: 5rem 0;
}

.main-opportunities__title {
  color: #fff;
  text-align: center;
  margin-bottom: 4rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
}

.main-opportunities-benefits {
  align-items: center;
  display: flex;
}

.main-opportunities-benefits__content {
  will-change: height;
  position: relative;
  overflow: hidden;
}

.main-opportunities-benefits__content-inner {
  padding-bottom: .5rem;
}

.main-opportunities-benefits__list {
  flex: auto;
  padding: 0;
  list-style-type: none;
}

.main-opportunities-benefits-progress {
  background: #fff3;
  height: 4px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.main-opportunities-benefits-progress__line {
  background: #014eff;
  width: 0;
  height: 4px;
}

.main-opportunities-benefits__list-item {
  cursor: pointer;
  border-bottom: 1px solid #fff3;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  position: relative;
}

.main-opportunities-benefits__list-item:last-child {
  margin-bottom: 0;
}

.main-opportunities-benefits__title {
  margin-bottom: .5rem;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  transition: color .3s;
}

.main-opportunities-benefits__list-item--active {
  border-bottom-width: 0;
}

.main-opportunities-benefits__list-item--active .main-opportunities-benefits__title {
  color: #fff;
}

.main-opportunities-benefits__list-item--active .main-opportunities-benefits__content {
  opacity: 1;
  height: auto;
}

.main-opportunities-benefits__list-item--active .main-opportunities-benefits-progress {
  display: block;
}

.main-opportunities-benefits-media {
  backdrop-filter: blur(15px);
  background: url("main-opportunities-benefits-media-bg.5a00799d.svg") 100% 100% / 100% no-repeat;
  border-radius: 1.5rem;
  flex: none;
  width: 26.625rem;
  height: 30rem;
  margin-left: 6.5rem;
}

.main-opportunities-benefits-media-image {
  height: 30rem;
  display: flex;
}

.main-opportunities-benefits-media-image img, .main-opportunities-benefits-media-image object {
  margin: auto;
}

.main-opportunities-benefits-media-image--conversion {
  width: 314px;
}

.main-opportunities-benefits-media-image--instil {
  width: 339px;
}

.main-opportunities-benefits__list-item .main-opportunities-benefits-media-image {
  display: none;
}

.main-quickstats {
  padding: 5rem 0;
}

.main-quickstats__list {
  margin-left: -1.88rem;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.main-quickstats__list-item {
  background: var(--primary-blue) url("main-quickstats-bd.a93634f5.png") no-repeat 50% 0 / auto 1px;
  color: #fff;
  border-radius: 1rem;
  flex: 1;
  margin-left: 1.88rem;
  padding: 1.5rem 2rem 2rem;
}

.main-quickstats__value {
  margin-bottom: 1rem;
  font-size: 4rem;
  font-weight: 500;
  line-height: normal;
  display: block;
}

.main-quickstats__label {
  letter-spacing: .025rem;
  font-size: 1.5rem;
  line-height: 1.875rem;
  display: block;
}

.main-otc {
  padding: 5rem 0;
}

.main-otc-content {
  letter-spacing: .025rem;
  text-align: center;
  max-width: 55rem;
  margin: 0 auto 5rem;
  font-size: 1.5rem;
  line-height: 1.875rem;
}

.main-otc-content p {
  margin-bottom: 2.5rem;
}

.main-otc-content__title {
  color: #fff;
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
}

.main-otc .main-list-benefits {
  padding: 2.5rem 0 .62rem;
}

.main-partners {
  padding: 5rem 0;
}

.main-partners__title {
  text-align: center;
  margin-bottom: 3.5rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
}

.main-partners-slider {
  display: flex;
  position: relative;
  overflow-x: auto;
}

.main-partners-slider__list {
  margin: auto;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.main-partners-slider__list-item {
  margin: 0 .5rem;
}

.main-fxcurriences {
  padding: 5rem 0;
}

.main-fxcurriences__inner {
  background-color: #15171c;
  border-radius: 1.5rem;
  display: flex;
}

.main-fxcurriences__content {
  flex: auto;
  padding: 5rem;
}

.main-fxcurriences__figure {
  background: url("fxcurriences-bg.a740570e.jpg") 0 0 / contain no-repeat;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  align-self: flex-end;
  width: 585px;
  height: 544px;
}

.main-fxcurriences__title {
  color: #fff;
  margin-bottom: 2.5rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
}

.main-fxcurriences__currencies {
  margin-bottom: 2.5rem;
  display: flex;
}

.main-fxcurriences-list {
  flex: 1;
  margin-left: -1.88rem;
  padding: 0;
  list-style-type: none;
}

.main-fxcurriences-list__list-item {
  align-items: center;
  padding: 0 0 1rem 1.88rem;
  display: flex;
}

.main-fxcurriences-list__content {
  letter-spacing: .025rem;
  margin-left: .69rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.875rem;
}

.main-fxcurriences-list__ico svg {
  vertical-align: top;
  width: 32px;
  height: 32px;
}

.main-fxcurriences-list__more {
  border: 1px solid var(--primary-blue);
  box-sizing: border-box;
  color: var(--primary-blue);
  text-align: center;
  border-radius: .5rem;
  flex: none;
  width: 2rem;
  height: 2rem;
  display: flex;
}

.main-fxcurriences-list__more svg {
  vertical-align: top;
  margin: auto;
}

.main-tradewithus {
  text-align: center;
  padding: 2.5rem 0 7.5rem;
}

.main-tradewithus__title {
  color: #fff;
  margin-bottom: 5rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
}

.main-tradewithus__list {
  justify-content: space-around;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.main-tradewithus__list-item {
  color: #fff;
  align-items: center;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  display: flex;
}

.main-tradewithus__ico {
  width: 48px;
  margin-right: 1rem;
}

.main-tradewithus__ico svg {
  vertical-align: top;
}

.main-touch {
  background: var(--primary-blue) url("touch-bg.4080efeb.jpg") no-repeat 50% / auto 396px;
  color: #fff;
  letter-spacing: .025rem;
  text-align: center;
  align-items: center;
  min-height: 24.75rem;
  font-size: 1.5rem;
  line-height: 1.875rem;
  display: flex;
}

.main-touch__title {
  margin-bottom: 1.5rem;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
}

.main-touch__content {
  max-width: 44.625rem;
  margin: 0 auto 2.5rem;
}

.main-touch__button .btn-light {
  color: #014fff;
  background-color: #fff;
}

.footer {
  padding: 2.5rem 0;
}

.footer-headline {
  border-bottom: 1px solid #2f2f2e;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
}

.footer-headline__logo svg {
  width: 152px;
  height: 30px;
}

.footer-cpr {
  font-size: .875rem;
  display: flex;
}

.footer-cpr__content, .footer-cpr__dev {
  flex: none;
}

.footer-nav {
  text-align: center;
  flex: auto;
}

.footer-nav__list {
  justify-content: center;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.footer-nav__list-item {
  padding: 0 1rem;
}

.footer-nav a {
  color: #999;
  text-decoration: none;
}

.footer-nav a:hover {
  text-decoration: underline;
}

.footer-cpr__dev a {
  color: #fff;
  text-decoration: none;
}

.footer-cpr__dev a:hover {
  text-decoration: underline;
}

.modal {
  display: none;
}

.modal.is-open {
  display: block;
}

.modal-backdrop {
  background: #08080cb3;
  padding: 2.5rem 1.25rem;
  display: flex;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.modal-container {
  background: #fff;
  border-radius: 1.5rem;
  width: 100%;
  max-width: 57.9rem;
  margin: auto;
  padding: 3rem;
  position: relative;
}

.modal-header {
  margin-bottom: 1.5rem;
}

.modal-header__close {
  cursor: pointer;
  color: currentColor;
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  padding: 0;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modal-header__close svg {
  vertical-align: top;
}

.modal-header__title {
  color: var(--dark-color);
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3.5rem;
}

.modal-header__subtitle {
  padding-top: .5rem;
}

.hs-input {
  background: var(--secondary-gray-light) !important;
  color: var(--primary-black, #0a0a0f) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
  font-family: var(--font-family) !important;
  letter-spacing: .0125rem !important;
  border: 1px solid #9999994d !important;
  border-radius: .25rem !important;
}

.c-light {
  color: #fff;
}

@media screen and (width <= 768px) {
  .btn {
    padding-top: .88rem;
    padding-bottom: .88rem;
  }

  .top__action-button {
    display: none;
  }

  .hero {
    background: url("hero-mobile-bg.d6d3169c.jpg") 100% 100% / cover no-repeat;
    min-height: 0;
    padding: 5.38rem 0 10.62rem;
  }

  .hero__subtitle {
    letter-spacing: .015rem;
    margin-bottom: .75rem;
    font-size: 1rem;
  }

  .hero-mainheader {
    letter-spacing: .0225rem;
    height: 5rem;
    margin-bottom: .75rem;
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 2.5rem;
  }

  .hero-mainheader__word {
    white-space: wrap;
    width: 10px;
  }

  .hero__content {
    letter-spacing: .0125rem;
    margin-bottom: 2rem;
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .main-services {
    padding-top: 2.5rem;
    padding-bottom: 1.5rem;
  }

  .main-services__list {
    flex-direction: column;
  }

  .main-services__list-item {
    margin-bottom: 1rem;
  }

  .main-services__item {
    letter-spacing: .0125rem;
    background-size: contain;
    padding: 1.5rem 1.5rem 9.25rem;
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .main-services__title {
    font-size: 1.375rem;
    line-height: 1.5rem;
  }

  .main-services__item--payment {
    background-size: 100%;
  }

  .main-services__item--otc {
    background-size: 100%;
    padding-bottom: 13.25rem;
  }

  .main-services__item--custodyservices {
    background-size: 100%;
    padding-bottom: 12rem;
  }

  .main-hero-payments {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .main-hero-payments__content {
    margin-bottom: 3.75rem;
    font-size: 1.375rem;
    line-height: 1.5rem;
  }

  .main-hero-payments__content p {
    margin-bottom: 1.5rem;
  }

  .main-hero-payments__title {
    margin-bottom: 2rem;
    font-size: 1.75rem;
    line-height: 2.125rem;
  }

  .main-hero-payments__visual {
    min-height: 240px;
    margin: 3.75rem 0;
  }

  .main-hero-payments__visual:before {
    background-position: 38% 0;
    height: 480px;
    margin-top: -80px;
  }

  .main-list-benefits__list {
    grid-template-columns: repeat(1, 1fr);
  }

  .main-list-benefits__list-item {
    margin-bottom: 1rem;
  }

  .main-hero-payments .main-list-benefits {
    margin-bottom: 5.25rem;
  }

  .main-hero-payments-coverage {
    flex-direction: column;
  }

  .main-hero-payments-coverage__title {
    margin-bottom: 2rem;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 2.125rem;
  }

  .main-hero-payments-coverage-list__flag {
    width: 1.5rem;
    height: 1.5rem;
  }

  .main-hero-payments-coverage__flags {
    margin-bottom: 1.5rem;
  }

  .main-hero-payments-coverage-list {
    margin-left: -.5rem;
  }

  .main-hero-payments-coverage-list__list-item {
    padding-left: .5rem;
  }

  .main-hero-payments-coverage__content {
    padding-bottom: 1.5rem;
  }

  .main-hero-payments-coverage-list__content {
    letter-spacing: .015rem;
    margin-left: .5rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .main-hero-payments-coverage-list__more {
    width: 1.5rem;
    height: 1.5rem;
  }

  .main-hero-payments-coverage__globe {
    text-align: center;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
  }

  .main-hero-payments-coverage__globe svg {
    max-width: 320px;
  }

  .main-revolution-payments {
    padding-top: 2.5rem;
    padding-bottom: 1.25rem;
  }

  .main-revolution-payments__title {
    margin-bottom: 2rem;
    font-size: 1.75rem;
    line-height: 2.125rem;
  }

  .main-revolution-payments__item {
    flex-direction: column;
    margin-bottom: 1.25rem;
  }

  .main-revolution-payments--optimize {
    background-image: none;
  }

  .main-revolution-payments--optimize .main-revolution-payments__figure {
    background: url("revolution-bg-1.5a903fdc.svg") 0 100% / contain no-repeat;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    width: 100%;
    height: 14.1228rem;
  }

  .main-revolution-payments__content {
    letter-spacing: .0125rem;
    padding: 1.5rem 1.25rem;
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .main-revolution-payments__content h3 {
    margin-bottom: 1rem;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.5rem;
  }

  .main-revolution-payments__content p {
    margin-bottom: 1.5rem;
  }

  .main-revolution-payments--optimize .main-revolution-payments__figure object {
    width: auto;
    height: 14.1228rem;
  }

  .main-revolution-payments--simplify {
    background-image: none;
  }

  .main-revolution-payments--simplify .main-revolution-payments__figure {
    background: url("revolution-bg-1.5a903fdc.svg") 0 100% / contain no-repeat;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    width: 100%;
    height: 14.1228rem;
    display: flex;
    transform: translate(0);
  }

  .main-revolution-payments--simplify .main-revolution-payments__figure img {
    width: 264px;
    margin: auto;
    transform: translate(-10px);
  }

  .main-revolution-payments--growth {
    background-image: none;
  }

  .main-revolution-payments--growth .main-revolution-payments__figure {
    background: url("revolution-bg-2.1d28bdec.svg") 0 100% / contain no-repeat;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    width: 100%;
    height: 14.1228rem;
    display: flex;
  }

  .main-revolution-payments--growth .main-revolution-payments__figure object {
    width: auto;
    height: 11.3125rem;
    margin: auto;
  }

  .main-sections-wrapper {
    background: url("main-sections-bg.00f38c0c.jpg") 0 0 / 320px no-repeat;
  }

  .main-opportunities {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .main-opportunities__title {
    margin-bottom: 3rem;
    font-size: 1.75rem;
    line-height: 2.125rem;
  }

  .main-opportunities-benefits {
    flex-direction: column;
    position: relative;
  }

  .main-opportunities-benefits__title {
    font-size: 1.375rem;
    line-height: 1.5rem;
  }

  .main-opportunities-benefits__content {
    letter-spacing: .0125rem;
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .main-opportunities-benefits__list-item {
    border-bottom-width: 1px;
    position: relative;
  }

  .main-opportunities-benefits__list-item--active {
    padding-bottom: 17.98rem;
  }

  .main-opportunities-benefits__list-item--active .main-opportunities-benefits-media-image {
    display: flex;
  }

  .main-opportunities-benefits-media {
    position: absolute;
    top: -9999rem;
    left: -9999rem;
  }

  .main-opportunities-benefits__list-item .main-opportunities-benefits-media-image {
    background-image: url("main-opportunities-benefits-media-bg.5a00799d.svg");
    background-position: 100% 100%;
    background-repeat: no-repeat;
    border-radius: .7rem;
    width: 13.0789rem;
    height: 14.7368rem;
    margin: 0 0 0 -6.53947rem;
    position: absolute;
    bottom: 1.75rem;
    left: 50%;
  }

  .main-opportunities-benefits-media-image--seamless {
    width: 170px;
  }

  .main-opportunities-benefits-media-image--instil, .main-opportunities-benefits-media-image--conversion {
    width: 160px;
  }

  .main-quickstats {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .main-quickstats__list {
    flex-direction: column;
  }

  .main-quickstats__list-item {
    margin-bottom: 1rem;
    padding: 1rem 1.5rem 1.5rem;
  }

  .main-quickstats__label {
    letter-spacing: .015rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .main-otc {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .main-otc-content {
    letter-spacing: .015rem;
    margin-bottom: 2.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .main-otc-content__title {
    margin-bottom: 2rem;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 2.125rem;
  }

  .main-otc .main-list-benefits {
    padding-top: 1.5rem;
    padding-bottom: 0;
  }

  .main-partners {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .main-partners__title {
    text-align: left;
    margin-bottom: 2rem;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.75rem;
    line-height: 2.125rem;
  }

  .main-partners__title .c-light {
    display: block;
  }

  .main-fxcurriences {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .main-fxcurriences__content {
    padding: 2.5rem 1.25rem;
  }

  .main-fxcurriences__title {
    margin-bottom: 2rem;
    font-size: 1.75rem;
    line-height: 2.125rem;
  }

  .main-fxcurriences-list__content {
    letter-spacing: .015rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .main-fxcurriences-list__list-item {
    padding-left: 1rem;
  }

  .main-fxcurriences__currencies {
    margin-bottom: 1.5rem;
  }

  .main-fxcurriences-list {
    margin-left: -1rem;
  }

  .main-fxcurriences-list__andmany {
    margin-right: -1.5rem;
  }

  .main-fxcurriences__inner {
    flex-direction: column;
  }

  .main-fxcurriences__figure {
    background-position: 100% 100%;
    width: 100%;
    height: 15.7949rem;
  }

  .main-tradewithus {
    padding-top: 2.5rem;
    padding-bottom: 1.5rem;
  }

  .main-tradewithus__title {
    margin-bottom: 2rem;
    font-size: 1.75rem;
    line-height: 2.125rem;
  }

  .main-tradewithus__list {
    flex-wrap: wrap;
  }

  .main-tradewithus__list-item {
    letter-spacing: .015rem;
    padding-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .main-touch {
    background-image: url("touchus-mobile-bg.f8ed9dd7.jpg");
    background-size: cover;
    min-height: 24.75rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .main-touch__content {
    letter-spacing: .015rem;
  }

  .main-touch__title {
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
    line-height: 2.125rem;
  }

  .footer-headline {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  .footer-headline__logo svg {
    width: 13.375rem;
    height: 2.62875rem;
  }

  .footer-cpr {
    text-align: center;
    flex-direction: column;
  }

  .footer-cpr__content {
    color: #fff;
    margin-bottom: 2rem;
  }

  .footer-nav__list {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .footer-nav__list-item {
    margin-bottom: 1rem;
  }

  .modal-container {
    padding: 2rem 1.25rem;
  }

  .modal-header__title {
    font-size: 1.75rem;
    line-height: 2.125rem;
  }

  .modal-header__close {
    width: 2.5rem;
    height: 2.5rem;
    top: .5rem;
    right: .5rem;
  }

  .modal-header__subtitle {
    letter-spacing: .0125rem;
    font-size: .875rem;
    line-height: 1.25rem;
  }
}
/*# sourceMappingURL=index.cc6ad255.css.map */
