@keyframes splide-loading{0%{transform:rotate(0)}to{transform:rotate(1turn)}}.splide__track--draggable{-webkit-touch-callout:none;-webkit-user-select:none;-ms-user-select:none;user-select:none}.splide__track--fade>.splide__list>.splide__slide{margin:0!important;opacity:0;z-index:0}.splide__track--fade>.splide__list>.splide__slide.is-active{opacity:1;z-index:1}.splide--rtl{direction:rtl}.splide__track--ttb>.splide__list{display:block}.splide__container{box-sizing:border-box;position:relative}.splide__list{backface-visibility:hidden;display:-ms-flexbox;display:flex;height:100%;margin:0!important;padding:0!important}.splide.is-initialized:not(.is-active) .splide__list{display:block}.splide__pagination{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-pack:center;justify-content:center;margin:0;pointer-events:none}.splide__pagination li{display:inline-block;line-height:1;list-style-type:none;margin:0;pointer-events:auto}.splide:not(.is-overflow) .splide__pagination{display:none}.splide__progress__bar{width:0}.splide{position:relative;visibility:hidden}.splide.is-initialized,.splide.is-rendered{visibility:visible}.splide__slide{backface-visibility:hidden;box-sizing:border-box;-ms-flex-negative:0;flex-shrink:0;list-style-type:none!important;margin:0;position:relative}.splide__slide img{vertical-align:bottom}.splide__spinner{animation:splide-loading 1s linear infinite;border:2px solid #999;border-left-color:transparent;border-radius:50%;bottom:0;contain:strict;display:inline-block;height:20px;left:0;margin:auto;position:absolute;right:0;top:0;width:20px}.splide__sr{clip:rect(0 0 0 0);border:0;height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.splide__toggle.is-active .splide__toggle__play,.splide__toggle__pause{display:none}.splide__toggle.is-active .splide__toggle__pause{display:inline}.splide__track{overflow:hidden;position:relative;z-index:0}

*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/* img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
} */
input, button, textarea, select {
  font: inherit;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
}


:root {
  --base-rem: 16px;
  --font-family: Inter,sans-serif;
  --base-color: #999;
  --dark-color: #0A0A0F;
  --btn-grad: linear-gradient(50deg, #0537A8 4.69%, #013BFF 88.09%);
  --primary-black: #0A0A0F;
  --primary-blue: #05F;
  --secondary-gray-light: #E8E8ED;
}

html {
  font-size: var(--base-rem);
}



body {
  color: var(--base-color);
  background-color: var(--dark-color);
  font-size:1rem;
  font-family: var(--font-family);
}

.container {
  position: relative;
}

/* FORMS */
.btn {
  background: none;
  border: none;
  white-space: normal; 
  padding: 15px 2rem 15px 2.5rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
}
.btn__arrow {
  display: inline-block;
  width: 20px;
  vertical-align: top;
  margin-top: 2px;
  margin-bottom: -2px;
}
.btn__arrow svg {
  vertical-align: top;
}

.btn-simple {
  padding: 0.44rem 0;
  color: currentColor;
}
.btn-simple:hover .btn__content {
  text-decoration: underline;
}
.btn-light {
  border-radius: 0.5rem;
  color: currentColor;
}
.btn-simple .btn__arrow {
  opacity: 0.5;
}
.btn-main {
  color: #fff;
  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: var(--btn-grad);
}
.btn-main:hover {
  border-color: rgba(255, 255, 255, 0.1);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(50deg, #0537A8 4.69%, #013BFF 88.09%);
}
.btn-main:active {
  border-radius: 8px;
  border-color:rgba(255, 255, 255, 0.1);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), linear-gradient(50deg, #0537A8 4.69%, #013BFF 88.09%);
}
/* END FORMS */

.container-inner {
  padding: 0 20px;
  max-width: 1210px;
  margin: 0 auto;
  width: 100%;
}

.logo svg {
  vertical-align: top;
  width: 152px;
  height: 30px;
}
.top {
  position: absolute;
  padding: 1rem 0;
  left: 0;
  right: 0;
  top: 0;
}

.top__action-button .btn {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0.375rem;
  background: rgba(255, 255, 255, 0.10);
  padding: 0.625rem 0.75rem 0.625rem 1rem;
}
.top__action-button .btn:hover {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.20);
}
.top__action-button .btn:active {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.20);
  background: rgba(255, 255, 255, 0.20);
}
.top__action-button .btn__arrow {
  opacity: 0.5;
  margin-top: 0;
}

.top .container-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero {
  background: url(../img/hero-block-md.jpg) no-repeat 100% 100%;
  min-height: 39.375rem;
  /* padding-top: 8.44rem; */
  display: flex;
  align-items: center;
  color: #fff;
}
.hero__inner {
  max-width: 50rem;
}
.hero__content {
  letter-spacing: 0.0125rem;
  max-width: 35.5625rem;
  margin-bottom: 2.5rem;
}
.hero__content p {
  margin-bottom: 1rem;
}
.hero__subtitle {
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: 0.025rem;
  margin-bottom: 1rem;
}
.hero-mainheader {
  color: var(--primary-blue);
  font-size: 3.75rem;
  line-height: 4.375rem;
  letter-spacing: 0.0375rem;
  font-weight: normal;
  margin-bottom: 1.5rem;
  overflow: hidden;
  height: 4.375rem;
  position: relative;
}
.hero-mainheader__word {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  white-space: nowrap;
  opacity: 0;
}
.hero-mainheader__word:nth-child(1) {
  opacity: 1;
}


.main-services {
  background-color: #fff;
  padding: 5rem 0;
}
.main-services__title {
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: normal;
  margin-bottom: 0.5rem;
}
.main-services__list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 0 -1.88rem;
  display: flex;
}

.main-services__soon {
  position: absolute;
  right: 0.62rem;
  bottom: 0.75rem;
  border-radius: 1.5rem;
  border: 1px solid #00A5FF;
  background: var(--primary-blue);
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: 500;
  padding: 0.5rem 1rem;
}

.main-services__list-item {
  flex: 1;
  margin-left: 1.88rem;
  display: flex;
}

.main-services__item {
  position: relative;
  padding: 2.5rem 2.5rem 10.25rem 2.5rem;
  border-radius: 1.5rem;
  backdrop-filter: blur(2px);
  letter-spacing: 0.0125rem;
}
.main-services__item-content {
  
}

.main-services__item--payment {
  background: #E8E8ED url(../img/main-services-payments.jpg) no-repeat 50% 100% / 370px auto;
  color: var(--primary-black);
}

.main-services__item--otc {
  color: #fff;
  background: var(--primary-blue) url(../img/main-services-otc.jpg) no-repeat 50% 100% / 370px auto;
}

.main-services__item--custodyservices {
  color: #fff;
  background: var(--primary-black) url(../img/main-services-custodyservices.jpg) no-repeat 50% 100% / 100% auto;
}



.main-hero-payments {
  padding: 7.5rem 0;
  position: relative;
}

.main-hero-payments__content {
  max-width: 50rem;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  margin-bottom: 7.5rem;
}
.main-hero-payments__content p {
  margin-bottom: 2.5rem;
}
.main-hero-payments__title {
  color: #fff;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
  margin-bottom: 2.5rem;
}
.main-hero-payments__visual {
  min-height: 35rem;
}
.main-hero-payments__visual:before {
  position: absolute;
  content: '';
  pointer-events: none;
  z-index: -1;
  height: 1180px;
  left: 0;
  right: 0;
  margin-top: -220px;
  background: url(../img/hero-payments.jpg) no-repeat 50% 0 / auto 100%;
}


@media 
(-webkit-min-device-pixel-ratio: 2), 
(min-resolution: 192dpi) { 
  .main-hero-payments__visual:before {
    background-image: url(../img/hero-payments@2x.jpg);
  }
}


.main-hero-payments .main-list-benefits {
  margin-bottom: 12.5rem;
}

.main-hero-payments__glow {
  position: absolute;
  left: 0;
  right: 0;
  background: url(../img/main-hero-payments-benefits-bg.jpg) no-repeat 50% / auto 100%;
  height: 700px;
  z-index: -1;
}

.main-list-benefits__list {
  margin-left: -1.88rem;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  
}
.main-list-benefits__content {
  max-width: 16.875rem;
}
.main-list-benefits__list-item {
  border-radius: 1rem;
  /* border: 1px solid rgba(153, 153, 153, 0.4) ; */
  background: rgba(31, 31, 31, 0.85) url(../img/main-list-benefits-bd.png) no-repeat 50% 0 / auto 1px;
  backdrop-filter: blur(8px);
  min-height: 11.25rem;
  margin-left: 1.88rem;
  margin-bottom: 1.88rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.625rem;
  color: #fff;
  padding: 1.5rem;
}

.main-list-benefits__ico {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.main-list-benefits__ico svg, .main-list-benefits__ico img {
  width: 48px;
  height: auto;
  vertical-align: top;
}

.main-hero-payments-coverage {
  display: flex;
}

.main-hero-payments-coverage__content {
  flex: 1 1 auto
}
.main-hero-payments-coverage__globe {
  flex: 0 0 auto;
  margin-left: 2.94rem;
  width: 37.5rem;
  height: 31.25rem;
}

.main-hero-payments-coverage__title {
  color: #fff;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
  margin-bottom: 2.5rem;
}
.main-hero-payments-coverage__flags {
  display: flex;
  margin-bottom: 3.5rem;
}
.main-hero-payments-coverage-list {
  list-style-type: none;
  padding: 0;
  margin-left: -1.88rem;
  flex: 1
}
.main-hero-payments-coverage-list__list-item {
  display: flex;
  padding: 0 0 1rem 1.88rem;
}
.main-hero-payments-coverage-list__content {
  margin-left: 0.69rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.875rem;
  letter-spacing: 0.025rem;
  padding-top: 1px;
}
.main-hero-payments-coverage-list__more {
  border-radius: 0.5rem;
  border: 1px solid var(--primary-blue);
  width: 2rem;
  height: 2rem;
  box-sizing: border-box;
  color:var(--primary-blue);
  text-align: center;
  display: flex;
  box-sizing: border-box;
}
.main-hero-payments-coverage-list__more svg {vertical-align: top; margin:auto}
.main-hero-payments-coverage-list__flag {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  flex: 0 0 auto
}


.main-revolution-payments {
  background-color: #fff;
  padding: 6rem 0 5rem;
}

.main-revolution-payments__title {
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
  color: var(--primary-black);
  margin-bottom: 3.5rem;
}
.main-revolution-payments__list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.main-revolution-payments__list-item {
  
}
.main-revolution-payments__item {
  position: relative;
  display: flex;
  border-radius: 1.5rem;
  min-height: 28.8rem;
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  /* transform: translate(0,100vh); */
}
.main-revolution-payments__figure {
  flex: 0 0 auto;
  width: 35.625rem;
  text-align: center;
}
.main-revolution-payments__figure img, .main-revolution-payments__figure object {
  vertical-align: top;
}
.main-revolution-payments__content {
  letter-spacing: 0.0125rem;
  padding: 1.88rem 4.38rem 1.88rem 1.88rem;
}
.main-revolution-payments__content h3 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  letter-spacing: normal;
  margin-bottom: 1rem;
}
.main-revolution-payments__content p {
  margin-bottom: 2rem;
}

.main-revolution-payments--optimize {
  background: var(--secondary-gray-light) url(../img/revolution-bg-1.svg) no-repeat 0 0 / auto 100%;
  color: var(--primary-black);
}
.main-revolution-payments--optimize .main-revolution-payments__figure object {
  width: 490px;
}
.main-revolution-payments--simplify {
  background: var(--primary-black) url(../img/revolution-bg-1.svg) no-repeat 0 0 / auto 100%;
  color: #fff;
}

.main-revolution-payments--simplify .main-revolution-payments__figure {
  transform: translate(-15px, 0);
}

.main-revolution-payments--growth {
  background: var(--primary-blue) url(../img/revolution-bg-2.svg) no-repeat 0 0 / auto 100%;
  color: #fff;
}
.main-revolution-payments--growth .main-revolution-payments__figure object {
  width: 398px;
}

.main-sections-wrapper {
  background: url(../img/main-section-bg.jpg) no-repeat 0 0 / 968px auto;
}

.main-opportunities {
  padding: 5rem 0;
}

.main-opportunities__title {
  color: #fff;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
  margin-bottom: 4rem;
  text-align: center;
}

.main-opportunities-benefits {
  display: flex;
  align-items: center
}
.main-opportunities-benefits__content {
  position: relative;
  overflow: hidden;
  will-change: height;
  /* opacity: 0; */
  /* height: 0; */
  /* position: relative;
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden; */
  /* padding-bottom: 0.5rem; */
}
.main-opportunities-benefits__content-inner {
  padding-bottom: 0.5rem;
}
.main-opportunities-benefits__list {
  flex: 1 1 auto;
  list-style-type: none;
  padding: 0;
}
.main-opportunities-benefits-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: rgba(255, 255, 255, 0.20);
  display: none;
}
.main-opportunities-benefits-progress__line {
  background: #014EFF;
  height: 4px;
  width: 0;
}
.main-opportunities-benefits__list-item {
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.20);
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
}
.main-opportunities-benefits__list-item:last-child {
  margin-bottom: 0;
}

.main-opportunities-benefits__title {
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  margin-bottom: 0.5rem;
  transition: color .3s;
}
.main-opportunities-benefits__list-item--active {
  border-bottom-width: 0;
}
.main-opportunities-benefits__list-item--active .main-opportunities-benefits__title {
  color: #fff;
}
.main-opportunities-benefits__list-item--active .main-opportunities-benefits__content {
  height: auto;
  opacity: 1;
}
.main-opportunities-benefits__list-item--active .main-opportunities-benefits-progress {
  display: block;
}



.main-opportunities-benefits-media {
  flex: 0 0 auto;
  width: 26.625rem;
  height: 30rem;
  background: #15171C;
  backdrop-filter: blur(15px);
  margin-left: 6.5rem;
  background: url(../img/main-opportunities-benefits-media-bg.svg) no-repeat 100% 100% / 100% auto;
  border-radius: 1.5rem;
}
.main-opportunities-benefits-media-image {
  display: flex;
  height: 30rem;
  display: flex;
}
.main-opportunities-benefits-media-image img, .main-opportunities-benefits-media-image object {
  margin: auto;
}

.main-opportunities-benefits-media-image--conversion {
  width: 314px;
}
.main-opportunities-benefits-media-image--instil {
  width: 339px;
}

.main-opportunities-benefits__list-item .main-opportunities-benefits-media-image {
  display: none;
}

.main-quickstats {
  padding: 5rem 0;
}

.main-quickstats__list {
  list-style-type: none;
  padding: 0;
  margin-left: -1.88rem;
  display: flex;
}
.main-quickstats__list-item {
  margin-left: 1.88rem;
  flex: 1;
  border-radius: 1rem;
  background: var(--primary-blue) url(../img/main-quickstats-bd.png) no-repeat 50% 0 / auto 1px;
  padding: 1.5rem 2rem 2rem;
  color: #fff;
}
.main-quickstats__value {
  display: block;
  font-size: 4rem;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1rem
}
.main-quickstats__label {
  display: block;
  font-size: 1.5rem;
  line-height: 1.875rem; /* 125% */
  letter-spacing: 0.025rem;
}

.main-otc {
  padding: 5rem 0;
}

.main-otc-content {
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: 0.025rem;
  max-width: 55rem;
  margin: 0 auto 5rem;
  text-align: center;
}
.main-otc-content p {
  margin-bottom: 2.5rem;
}

.main-otc-content__title {
  color: #fff;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
  margin-bottom: 2.5rem;
  text-align: center;
}


.main-otc .main-list-benefits {
  padding: 2.5rem 0 0.62rem;
}

.main-partners {
  padding: 5rem 0;
}
.main-partners__title {
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
  text-align: center;
  margin-bottom: 3.5rem;
}

.main-partners-slider {
  position: relative;
  overflow-x: auto;
  display: flex;
}
.main-partners-slider__list {
  display: flex;
  /* justify-content: center; */
  padding: 0;
  list-style-type: none;
  margin: auto;
}
.main-partners-slider__list-item {
  margin:0 .5rem;
}

.main-fxcurriences {
  padding: 5rem 0;
}
.main-fxcurriences__inner {
  border-radius: 1.5rem;
  background-color:#15171C;
  display: flex;
}
.main-fxcurriences__content {
  flex: 1 1 auto;
  padding: 5rem;
}
.main-fxcurriences__figure {
  background: url(../img/fxcurriences-bg.jpg) no-repeat 0 0 / contain;
  width: 585px;
  height: 544px;
  align-self: flex-end;
  border-bottom-right-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}
.main-fxcurriences__title {
  color: #fff;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
  margin-bottom: 2.5rem;
}
.main-fxcurriences__currencies {
  display: flex;
  margin-bottom: 2.5rem;
}
.main-fxcurriences-list {
  list-style-type: none;
  padding: 0;
  margin-left: -1.88rem;
  flex: 1;
}
.main-fxcurriences-list__list-item {
  display: flex;
  padding: 0 0 1rem 1.88rem;
  align-items: center;
}
.main-fxcurriences-list__content {
  margin-left: 0.69rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.875rem;
  letter-spacing: 0.025rem;
}
.main-fxcurriences-list__ico svg {
  width: 32px;
  height: 32px;
  vertical-align: top;
}
.main-fxcurriences-list__more {
  border-radius: 0.5rem;
  border: 1px solid var(--primary-blue);
  width: 2rem;
  height: 2rem;
  box-sizing: border-box;
  color:var(--primary-blue);
  text-align: center;
  display: flex;
  flex: 0 0 auto
}
.main-fxcurriences-list__more svg {vertical-align: top; margin:auto}

.main-tradewithus {
  padding: 2.5rem 0 7.5rem;
  text-align: center;
}
.main-tradewithus__title {
  color: #fff;
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
  margin-bottom: 5rem;
}

.main-tradewithus__list {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.main-tradewithus__list-item {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  color: #fff;
}
.main-tradewithus__ico {
  width: 48px;
  margin-right: 1rem;
}
.main-tradewithus__ico svg {
  vertical-align: top;
}

.main-touch {
  min-height: 24.75rem;
  background:var(--primary-blue) url(../img/touch-bg.jpg) no-repeat 50% / auto 396px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: 0.025rem;
  text-align: center;
}
.main-touch__title {
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.5rem;
  margin-bottom: 1.5rem;
}
.main-touch__content {
  max-width: 44.625rem;
  margin: 0 auto 2.5rem;
}
.main-touch__button .btn-light {
  background-color: #fff;
  color: #014FFF;

}

.footer {
  padding: 2.5rem 0;
}
.footer-headline {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #2F2F2E;
}
.footer-headline__logo svg {
  width: 152px;
  height: 30px;
}

.footer-cpr {
  display: flex;
  font-size: 0.875rem;
}
.footer-cpr__content {
  flex: 0 0 auto;
}
.footer-cpr__dev {
  flex: 0 0 auto;
}
.footer-nav {
  flex: 1 1 auto;
  text-align: center;
}
.footer-nav__list {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style-type: none;
}
.footer-nav__list-item {
  padding: 0 1rem;
}
.footer-nav a {
  color:#999;
  text-decoration: none;
}
.footer-nav a:hover {
  text-decoration: underline;
}
.footer-cpr__dev a {
  color: #fff;
  text-decoration: none;
}
.footer-cpr__dev a:hover {
  text-decoration: underline;
}

.modal {
  display: none;
}
.modal.is-open {
  display: block;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(8, 8, 12, 0.70);
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  padding: 2.5rem 1.25rem;
  overflow-y: auto;
}

.modal-container {
  border-radius: 1.5rem;
  background: #FFF;
  width: 100%;
  max-width: 57.9rem;
  padding: 3rem;
  position: relative;
  margin: auto;
}
.modal-header {
  margin-bottom: 1.5rem;
}
.modal-header__close {
  position: absolute;
  cursor: pointer;
  color: currentColor;
  border: none;
  padding: 0;
  background: none;
  width: 40px;
  height: 40px;
  top: 1rem;
  right: 1rem;
}
.modal-header__close svg {
  vertical-align: top;
}
.modal-header__title {
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3.5rem;
  color: var(--dark-color);
}
.modal-header__subtitle {
  padding-top: 0.5rem;
}
.modal-content {

}

.hs-input {
  border-radius: 0.25rem !important;
  border: 1px solid rgba(153, 153, 153, 0.30) !important;
  background: var(--secondary-gray-light) !important;
  color: var(--primary-black, #0A0A0F) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
  font-family: var(--font-family) !important;
  letter-spacing: 0.0125rem !important;
}

/* HELPERS */
.c-light {
  color: #fff;
}
/* END HELPERS */

@media screen and (max-width:768px) {
  /* .top {
    position: relative;
  } */
  
  .btn {
    padding-top: 0.88rem;
    padding-bottom: 0.88rem;
  }

  .top__action-button {
    display: none;
  }
  .hero {
    min-height: 0;
    padding: 5.38rem 0 10.62rem;
    background: url(../img/hero-mobile-bg.jpg) no-repeat 100% 100% / cover;
  }
  .hero__subtitle {
    font-size: 1rem;
    letter-spacing: 0.015rem;
    margin-bottom: 0.75rem;
  }
  .hero-mainheader {
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.0225rem;
    margin-bottom: 0.75rem;
    height: 5rem;
  }
  .hero-mainheader__word {
    width: 10px;
    white-space: wrap;
  }
  .hero__content {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.0125rem;
    margin-bottom: 2rem;
  }

  .main-services {
    padding-top: 2.5rem;
    padding-bottom: 1.5rem;
  }

  .main-services__list {
    flex-direction: column;
  }
  .main-services__list-item {
    margin-bottom: 1rem;
  }
  .main-services__item {
    padding: 1.5rem 1.5rem 9.25rem;
    background-size: contain;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.0125rem;
  }
  .main-services__title {
    font-size: 1.375rem;
    line-height: 1.5rem; /* 109.091% */
  }
  .main-services__item--payment {
    background-size: 100% auto;
  }
  .main-services__item--otc {
    padding-bottom: 13.25rem;
    background-size: 100% auto;
  }
  .main-services__item--custodyservices {
    padding-bottom: 12rem;
    background-size: 100% auto;
  }

  .main-hero-payments {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .main-hero-payments__content {
    font-size: 1.375rem;
    line-height: 1.5rem;
    margin-bottom: 3.75rem;
  }
  .main-hero-payments__content p {
    margin-bottom: 1.5rem;
  }
  .main-hero-payments__title {
    font-size: 1.75rem;
    line-height: 2.125rem;
    margin-bottom: 2rem;
  }
  .main-hero-payments__visual {
    min-height: 240px;
    margin: 3.75rem 0;
  }
  .main-hero-payments__visual:before {
    height: 480px;
    margin-top: -80px;
    background-position: 38% 0;
  }
  .main-list-benefits__list {
    grid-template-columns: repeat(1, 1fr);
  }
  .main-list-benefits__list-item {
    margin-bottom: 1rem;
  }
  .main-hero-payments .main-list-benefits {
    margin-bottom: 5.25rem;
  }

  .main-hero-payments-coverage {
    flex-direction: column;
  }

  .main-hero-payments-coverage__title {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 2.125rem;
    margin-bottom: 2rem;
  }

  .main-hero-payments-coverage-list__flag {
    width: 1.5rem;
    height: 1.5rem;
  }
  .main-hero-payments-coverage__flags {
    margin-bottom: 1.5rem;
  }
  .main-hero-payments-coverage-list {
    margin-left: -0.5rem;
  }
  .main-hero-payments-coverage-list__list-item {
    padding-left: 0.5rem;
  }
  .main-hero-payments-coverage__content {
    padding-bottom: 1.5rem;
  }
  .main-hero-payments-coverage-list__content {
    margin-left: 0.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.015rem;
  }
  .main-hero-payments-coverage-list__more {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .main-hero-payments-coverage__globe {
    padding: 0;
    margin: 0;
    width: auto;
    height: auto;
    text-align: center;
  }
  .main-hero-payments-coverage__globe svg {
    max-width: 320px;
  }
  .main-revolution-payments {
    padding-top: 2.5rem;
    padding-bottom: 1.25rem;
  }
  .main-revolution-payments__title {
    font-size: 1.75rem;
    line-height: 2.125rem;
    margin-bottom: 2rem;
  }
  .main-revolution-payments__list-item {
    
  }
  .main-revolution-payments__item {
    flex-direction: column;
    margin-bottom: 1.25rem;
  }

  .main-revolution-payments--optimize {
    background-image: none;
  }
  .main-revolution-payments--optimize .main-revolution-payments__figure {
    background: url(../img/revolution-bg-1.svg) no-repeat 0 100% / contain;
    height: 14.12281rem;
    width: 100%;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  .main-revolution-payments__content {
    padding: 1.5rem 1.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem; /* 142.857% */
    letter-spacing: 0.0125rem;
  }
  .main-revolution-payments__content h3 {
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
  .main-revolution-payments__content p {
    margin-bottom: 1.5rem;
  }
  .main-revolution-payments--optimize .main-revolution-payments__figure object {
    height: 14.1228rem;
    width: auto;
  }
  .main-revolution-payments--simplify {
    background-image: none; 
  }
  .main-revolution-payments--simplify .main-revolution-payments__figure {
    background: url(../img/revolution-bg-1.svg) no-repeat 0 100% / contain;
    height: 14.12281rem;
    width: 100%;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    transform: translate(0,0);
    display: flex;
  }

  .main-revolution-payments--simplify .main-revolution-payments__figure img {
    width: 264px;
    margin: auto;
    transform: translate(-10px,0);
  }
  
  .main-revolution-payments--growth {
    background-image: none;
  }
  .main-revolution-payments--growth .main-revolution-payments__figure {
    background: url(../img/revolution-bg-2.svg) no-repeat 0 100% / contain;
    height: 14.12281rem;
    width: 100%;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    display: flex;
  }

  .main-revolution-payments--growth .main-revolution-payments__figure object {
    height: 11.3125rem;
    width: auto;
    margin: auto;
  }

  .main-sections-wrapper {
    background: url(../img/main-sections-bg.jpg) no-repeat 0 0 / 320px;
  }
  .main-opportunities {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  
  .main-opportunities__title {
    font-size: 1.75rem;
    line-height: 2.125rem;
    margin-bottom: 3rem;
  }
  .main-opportunities-benefits {
    position: relative;
    flex-direction: column;
  }
  .main-opportunities-benefits__title {
    font-size: 1.375rem;
    line-height: 1.5rem;
  }
  .main-opportunities-benefits__content {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.0125rem;
  }
  .main-opportunities-benefits__list-item {
    position: relative;
    border-bottom-width: 1px;
    
  }
  .main-opportunities-benefits__list-item--active {
    padding-bottom: 17.98rem;
  }
  .main-opportunities-benefits__list-item--active .main-opportunities-benefits-media-image {
    display: flex;
  }

  /* display: flex; */

  /* .main-opportunities-benefits__list-item:last-child {
    border-bottom-width: 0;
  } */
  .main-opportunities-benefits-media {
    position: absolute;
    left: -9999rem;
    top: -9999rem;
    /* position: absolute;
    bottom: 1.75rem;
    left: 50%;
    height: 14.73681rem;
    width: 13.07894rem;
    margin: 0 0 0 -6.53947rem;
    background-image: url(../img/main-opportunities-benefits-media-bg.svg);
    background-repeat: no-repeat;
    background-position: 100% 100%;
    border-radius: 0.7rem;
    margin-top: 1.5rem; */
  }
  .main-opportunities-benefits__list-item .main-opportunities-benefits-media-image {
    position: absolute;
    bottom: 1.75rem;
    left: 50%;
    height: 14.73681rem;
    width: 13.07894rem;
    margin: 0 0 0 -6.53947rem;
    background-image: url(../img/main-opportunities-benefits-media-bg.svg);
    background-repeat: no-repeat;
    background-position: 100% 100%;
    border-radius: 0.7rem;
  }
  .main-opportunities-benefits-media-image--seamless {
    width: 170px;
  }
  .main-opportunities-benefits-media-image--instil {
    width: 160px;
  }
  .main-opportunities-benefits-media-image--conversion {
    width: 160px;
  }

  .main-quickstats {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .main-quickstats__list {
    flex-direction: column;
  }
  .main-quickstats__list-item {
    margin-bottom: 1rem;
    padding: 1rem 1.5rem 1.5rem;
  }
  .main-quickstats__label {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.015rem;
  }
  .main-otc {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .main-otc-content {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.015rem;
    margin-bottom: 2.5rem;
  }
  .main-otc-content__title {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 2.125rem;
    margin-bottom: 2rem;
  }
  .main-otc .main-list-benefits {
    padding-top: 1.5rem;
    padding-bottom: 0;
  }
  .main-partners {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .main-partners__title {
    font-size: 1.75rem;
    line-height: 2.125rem;
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
    margin-bottom: 2rem
  }
  .main-partners__title .c-light {
    display: block;
  }
  .main-fxcurriences {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .main-fxcurriences__content {
    padding: 2.5rem 1.25rem;
  }
  .main-fxcurriences__title {
    font-size: 1.75rem;
    line-height: 2.125rem;
    margin-bottom: 2rem;
  }
  .main-fxcurriences-list__content {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.015rem;
  }
  .main-fxcurriences-list__list-item {
    padding-left: 1rem;
  }
  .main-fxcurriences__currencies {
    margin-bottom: 1.5rem;
  }
  /* margin-right: 0.5rem; */
    
  .main-fxcurriences-list {
    margin-left: -1rem;
  }
  .main-fxcurriences-list__andmany {
    margin-right: -1.5rem;
  }
  .main-fxcurriences__inner {
    flex-direction: column;
  }
  .main-fxcurriences__figure {
    width: 100%;
    height: 15.79488rem;
    background-position: 100% 100%;
  }
  .main-tradewithus {
    padding-top: 2.5rem;
    padding-bottom:1.5rem;
  }
  .main-tradewithus__title {
    font-size: 1.75rem;
    line-height: 2.125rem; /* 121.429% */
    margin-bottom: 2rem;
  }
  .main-tradewithus__list {
    flex-wrap: wrap;
  }
  .main-tradewithus__list-item {
    font-size: 1rem;
    line-height: 1.5rem; /* 150% */
    letter-spacing: 0.015rem;
    padding-bottom: 1rem;
  }
  .main-touch {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-image: url(../img/touchus-mobile-bg.jpg);
    background-size: cover;
    min-height: 24.75rem;
    font-size: 1rem;
    line-height: 1.5rem; /* 150% */
  }
  .main-touch__content {
    letter-spacing: 0.015rem;
  }
  .main-touch__title {
    font-size: 1.75rem;
    line-height: 2.125rem;
    margin-bottom: 1.5rem;
  }
  .footer-headline {
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .footer-headline__logo svg {
    width: 13.375rem;
    height: 2.62875rem;
  }
  .footer-cpr {
    flex-direction: column;
    text-align: center;
  }
  .footer-cpr__content {
    color: #fff;
    margin-bottom: 2rem;
  }
  .footer-nav__list {
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .footer-nav__list-item {
    margin-bottom: 1rem;
  }
  .modal-container {
    padding: 2rem 1.25rem;
  }
  .modal-header__title {
    font-size: 1.75rem;
    line-height: 2.125rem;
  }
  .modal-header__close {
    width: 2.5rem;
    height: 2.5rem;
    top: 0.5rem;
    right: 0.5rem;
  }
  .modal-header__subtitle {
    font-size: 0.875rem;
    line-height: 1.25rem; /* 142.857% */
    letter-spacing: 0.0125rem;
  }
}